import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/seo";
import {StaticImage} from "gatsby-plugin-image";

export default () => {

    return (<Layout>
        <div className="grid gap-8 xl:grid-cols-2">
            <div className={'-mt-10 -mx-16 xl:m-0'}>
                <StaticImage src={'../assets/images/thomas_reiser.jpg'} alt={'Thomas Reiser'} width={825}
                             className={'w-full xl:w-[825px] float-right xl:mr-16'}
                             loading={'eager'}
                ></StaticImage>
            </div>
            <div id={'content'} className={'animate-fade-in'}>
                <h1>Thomas Reiser</h1>
                <p className={'my-2.5'}>
                    Rechtsanwalt, auch Schlichtungsstelle nach BaySchlG
                </p>
                <h2 className={'mt-5 mb-2.5'}>Ihr Ansprechpartner für</h2>
                <ul role={'list'} className={'leading-tight'}>
                    <li>Familienrecht</li>
                    <li>Erbrecht</li>
                    <li>Mietrecht</li>
                    <li>Verkehrsrecht</li>
                    <li>Inkasso und Strafrecht</li>
                </ul>
                <h2 className={'mt-5 mb-2.5'}>Vita</h2>
                <ul role={'list'} className={'leading-tight'}>
                    <li>Jahrgang 1970</li>
                    <li>Studium der Rechtswissenschaften an der Universität Augsburg</li>
                    <li>Referendariat am Landgericht Memmingen</li>
                    <li>Zulassung zur Anwaltschaft im Jahr 1998</li>
                </ul>
                <h2 className={'mt-5 mb-2.5'}>Mitgliedschaft</h2>
                <ul role={'list'} className={'leading-tight'}>
                    <li>Deutscher Anwaltverein DAV</li>
                    <li>Anwaltsverein Neu-Ulm/Günzburg</li>
                </ul>
                <h2 className={'mt-5 mb-2.5'}>Sprachen</h2>
                <ul role={'list'} className={'leading-tight'}>
                    <li>Deutsch</li>
                    <li>Englisch</li>
                    <li>Italienisch</li>
                </ul>
            </div>
        </div>
    </Layout>)
};

export const Head = () => {
    return (<SEO title={'Rechtsanwalt'}/>);
}
